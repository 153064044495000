import xss from 'xss';
import { convertShortcodeHyphen } from './convertShortcodeHyphen';
import { convertShortcodeDivider } from './convertShortcodeDivider';
import { convertShortcodeBlockQuote } from './convertShortcodeBlockQuote';
import { xssOptions } from './allowedTagsAndAttributes';
import { convertHtmlToReact } from './convertHtmlToReact';

export const processBodyText = (bodyText: string) => {
  const sanitizedBodyText = xss(bodyText, xssOptions);
  const withHyphens = convertShortcodeHyphen(sanitizedBodyText);
  const withDivider = convertShortcodeDivider(withHyphens);
  const withBlockQuote = convertShortcodeBlockQuote(withDivider);
  const processedBodyText = convertHtmlToReact(withBlockQuote);
  return processedBodyText;
};
