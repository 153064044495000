/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { ContentChangeListener } from '@volkswagen-onehub/audi-content-service';

import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { AppContent, FeatureAppProps } from '../@types/interfaces';

import { EditorialText } from './components/EditorialText';
import { normalizeContent } from './components/normalizeContent';

import mockContent from './content.json';

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  const {
    contentService,
    trackingService: trackingServiceInherited,
    footnoteReferenceService,
    vueFormatterService,
    localeService,
  } = inherited;

  let trackingService = {} as TrackingServiceV2;

  if (typeof window !== 'undefined') {
    trackingService = trackingServiceInherited!;
  }

  const [rawContent, setRawContent] = useState(contentService?.getContent() as AppContent);
  contentService?.onContentChange(setRawContent as ContentChangeListener);

  useEffect(() => {
    trackingService.featureAppName = __FEATURE_APP_NAME__;
    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  useEffect(() => {
    const listener = () => {
      setRawContent(normalizeContent(contentService.getContent() as AppContent));
    };

    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService, setRawContent]);

  const content = normalizeContent(rawContent ?? mockContent);

  return (
    <EditorialText
      content={content}
      footnoteReferenceService={footnoteReferenceService}
      vueFormatterService={vueFormatterService}
      localeService={localeService}
    />
  );
};

export default FeatureApp;
