import type { IFilterXSSOptions } from 'xss';

export const xssOptions: IFilterXSSOptions = {
  whiteList: {
    b: [],
    u: [],
    strong: [],
    a: ['href', 'name', 'target', 'rel'],
    p: [],
    h1: [],
    h2: [],
    h3: ['data-test-id'],
    h4: [],
    h5: [],
    h6: [],
    ul: [],
    ol: [],
    li: [],
    pre: [],
    blockquote: ['cite'],
    hr: [],
    span: ['style'],
    div: [],
  },
  css: {
    whiteList: {
      'text-decoration': /^underline$/,
    },
  },
};
